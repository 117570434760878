<template>
  <div data-app>
    <Header
      :username="this.username"
      :account_name="this.account_name"
      :unit="this.unit"
      :accountselected="this.account_selected"
      isHome="true"
      :accounts="this.accounts_list"
    ></Header>
    <div class="bgimage">
      <v-img
        v-if="isEnergy"
        src="../assets/images/Energy_Oil-min.jpeg"
        height="100%"
        max-width="100%"
      ></v-img>
      <v-img
        v-if="!isEnergy"
        src="../assets/images/Adobe1-min.jpeg"
        height="100%"
        max-width="100%"
      ></v-img>
    </div>

    <transition name="slide-fade">
      <div v-if="show">
        <div class="home-content">
          <v-card color="basil">
            <v-card-title
              class="text-center justify-center py-6 main-header-component"
            >
              <h1 v-bind:class="{ isEnergy: isEnergy, isFarm: !isenergy }">
                Account Documents
              </h1>
              <v-btn class="downloadbtn" @click="handleAllAccountsDownload()"
                >Download Documents</v-btn
              >
            </v-card-title>

            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              grow
            >
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-row>
                <v-col md="3">
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <div class="folder-element">
                          <MainFolder
                            :item_title="year.year"
                            type="year"
                            v-for="(year, idx) in years_list"
                            :key="idx"
                            item_month=""
                            :item_year="year.year"
                            category="Statements"
                            :isenergy="isEnergy"
                            :path="`Statements.${idx}`"
                            @download-clicked="onDownloadClicked"
                          >
                            <div v-for="month in months" v-bind:key="month">
                              <MainFolder
                                v-if="documents_filtered['Statements'][idx] && 
                                  documents_filtered['Statements'][idx][month]
                                    .length
                                "
                                category="Statements"
                                :item_title="`${month} (${documents_filtered['Statements'][idx][month].length})`"
                                :isenergy="isEnergy"
                                :item_month="month"
                                :item_year="year.year"
                                :path="`Statements.${idx}.${month}`"
                                @download-clicked="onDownloadClicked"
                              >
                                <div
                                  v-for="doc in documents_filtered[
                                    'Statements'
                                  ][idx][month]"
                                  v-bind:key="doc.id"
                                >
                                  <FileElement
                                    v-if="doc.doc_month == month"
                                    :doc="doc"
                                  ></FileElement>
                                </div>
                              </MainFolder>
                            </div>
                          </MainFolder>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-col>
                <v-col md="3">
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <div class="folder-element">
                          <!-- 1099 -->
                          <MainFolder
                            :item_title="year.year"
                            type="year"
                            v-for="(year, idx) in years_list"
                            :key="idx"
                            item_month=""
                            :item_year="year.year"
                            category="Annual_Documents"
                            :path="`Annual Documents.${idx}`"
                            @download-clicked="onDownloadClicked"
                          >
                            <MainFolder
                        
                              :item_title="1099"
                              category="Annual_Documents_1099"
                              item_month=""
                              :item_year="year.year"
                              :path="`Annual Documents.${idx}.1099`"
                              @download-clicked="onDownloadClicked"
                            >
                              <!-- <div v-for="month in months" v-bind:key="month"> -->
                              <!-- <MainFolder v-if="documents_filtered['Annual Documents'][idx]['1099'].length" :item_title="`(${documents_filtered['Annual Documents'][idx]['1099'].length})`"> -->
                              <div
                                v-for="doc in documents_filtered[
                                  'Annual Documents'
                                ][idx]['1099']"
                                v-bind:key="doc.id"
                              >
                                <FileElement :doc="doc" :is1099="true"></FileElement>
                              </div>
                              <!-- </MainFolder> -->
                              <!-- </div> -->
                            </MainFolder>

                            <!-- Statements -->
                            <MainFolder
                              :item_title="`Annual Statements`"
                              category="Annual_Documents_Statements"
                              item_month=""
                              :item_year="year.year"
                              :path="`Annual Documents.${idx}`"
                              @download-clicked="onDownloadClicked"
                            >
                              <div v-for="month in months" v-bind:key="month">
                                <MainFolder
                                  v-if="documents_filtered['Annual Documents'][idx] && 
                                    documents_filtered['Annual Documents'][idx][
                                      month
                                    ].length
                                  "
                                  :item_month="month"
                                  :item_year="year.year"
                                  category="Annual_Documents_Statements"
                                  :item_title="`${month} (${documents_filtered['Annual Documents'][idx][month].length})`"
                                  :path="`Annual Documents.${idx}`"
                                  @download-clicked="onDownloadClicked"
                                >
                                  <div
                                    v-for="doc in documents_filtered[
                                      'Annual Documents'
                                    ][idx][month]"
                                    v-bind:key="doc.id"
                                  >
                                    <FileElement
                                      v-if="doc.doc_month == month"
                                      :doc="doc"
                                    ></FileElement>
                                  </div>
                                </MainFolder>
                              </div>
                            </MainFolder>
                          </MainFolder>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-col>
                <v-col md="3">
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <div class="folder-element">
                          <MainFolder
                            :item_title="year.year"
                            type="year"
                            v-for="(year, idx) in years_list"
                            :key="idx"
                            item_month=""
                            :item_year="year.year"
                            category="Reports"
                            :isenergy="isEnergy"
                            :path="`Reports.${idx}`"
                            @download-clicked="onDownloadClicked"
                          >
                            <div v-for="month in months" v-bind:key="month">
                              <MainFolder
                                v-if="documents_filtered['Reports'][idx] &&
                                  documents_filtered['Reports'][idx][month]
                                    .length
                                "
                                :item_title="`${month} (${documents_filtered['Reports'][idx][month].length})`"
                                :item_month="month"
                                :item_year="year.year"
                                :path="`Reports.${idx}.${month}`"
                                @download-clicked="onDownloadClicked"
                              >
                                <div
                                  v-for="doc in documents_filtered['Reports'][
                                    idx
                                  ][month]"
                                  v-bind:key="doc.id"
                                >
                                  <FileElement
                                    v-if="doc.doc_month == month"
                                    :doc="doc"
                                  ></FileElement>
                                </div>
                              </MainFolder>
                            </div>
                          </MainFolder>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-col>
                <v-col md="3">
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <div class="folder-element">
                          <MainFolder
                            :item_title="year.year"
                            type="year"
                            v-for="(year, idx) in years_list"
                            :key="idx"
                            item_month=""
                            :item_year="year.year"
                            category="Documents"
                            :isenergy="isEnergy"
                            :path="`Documents.${idx}`"
                            @download-clicked="onDownloadClicked"
                          >
                            <div v-for="month in months" v-bind:key="month">
                              <MainFolder
                                v-if="documents_filtered['Documents'][idx] && 
                                  documents_filtered['Documents'][idx][month]
                                    .length
                                "
                                :item_title="`${month} (${documents_filtered['Documents'][idx][month].length})`"
                                :item_month="month"
                                :item_year="year.year"
                                :path="`Documents.${idx}.${month}`"
                                @download-clicked="onDownloadClicked"
                              >
                                <div
                                  v-for="doc in documents_filtered['Documents'][
                                    idx
                                  ][month]"
                                  v-bind:key="doc.id"
                                >
                                  <FileElement
                                    v-if="doc.doc_month == month"
                                    :doc="doc"
                                  ></FileElement>
                                </div>
                              </MainFolder>
                            </div>
                          </MainFolder>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-col>
              </v-row>
            </v-tabs-items>
          </v-card>
        </div>
      </div>
    </transition>
    <DownloadModal
      :dialog="showDownloadModal"
      :data="downloadData"
      :started="downloadStarted"
      @download-confirm="onDownloadConfirm"
      @cancel-clicked="onCancelClicked"
    />
    <AccountsDownload
      v-if="showAccountsDownload"
      :dialog="showAccountsDownload"
      :cpmkey="showAccountsDownloadKey"
      :started="accountsDownloadStarted"
      :months="months"
      :years="[current_year, 2023, 2022]"
      :accounts_list="this.accounts_list"
      @accounts-cancel-clicked="showAccountsDownload = false"
    >
    </AccountsDownload>
    <div id="loader" v-if="!dateloaded">
      <div class="content">
        <v-img
          src="../assets/images/fnlogo.png"
          max-height="150"
          max-width="250"
        ></v-img>
        <h4>Loading Content...</h4>
      </div>
    </div>
  </div>
</template>
<script>
import http from "../http-common";
import moment from "moment";
import Header from "./Header";
import MainFolder from "./MainFolder";
import FileElement from "./FileElement";
import DownloadModal from "./DownloadModal.vue";
import AccountsDownload from "./AccountsDownload.vue";
import { saveAs } from "file-saver";

export default {
  components: {
    Header,
    MainFolder,
    FileElement,
    DownloadModal,
    AccountsDownload
  },
  data() {
    return {
      dateloaded: false,
      show: false,
      tab: null,
      items: ["Statements", "Annual Documents", "Reports", "Miscellaneous"],
      username: "",
      account_name: "",
      portal_login: null,
      unit: "",
      isEnergy: false,
      documents_filtered: {
        Statements: [
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
        ],
        "Annual Documents": [
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
            "1099": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
            "1099": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
        ],
        Reports: [
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
        ],
        Documents: [
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
          {
            "January": [],
            "February": [],
            "March": [],
            "April": [],
            "May": [],
            "June": [],
            "July": [],
            "August": [],
            "September": [],
            "October": [],
            "November": [],
            "December": [],
          },
        ],
      },
      current_year: "",
      last_year: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      current_year_files: [],
      last_year_files: [],
      account: null,
      accounts_list: [],
      account_selected: false,
      start_year: 2022,
      years_list: [],
      showDownloadModal: false,
      downloadData: null,
      downloadStarted: false,
      showAccountsDownload: false,
      accountsDownloadData: {},
      accountsDownloadStarted: false,
      showAccountsDownloadKey: 0,
    };
  },
  methods: {
    onDownloadClicked(e) {
      console.log(e);
      Object.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
        s = s.replace(/^\./, ""); // strip a leading dot
        var a = s.split(".");
        for (var i = 0, n = a.length; i < n; ++i) {
          var k = a[i];
          if (k in o) {
            o = o[k];
          } else {
            return;
          }
        }
        return o;
      };
      const folder_files = Object.byString(this.documents_filtered, e.path);
      let files_list = {
        category: e.category,
        data: {},
        type: null,
        year: e.year,
      };
      if (e.type === "year") {
        files_list.type = e.type;
        Object.keys(folder_files).forEach((item) => {
          if (folder_files[item].length > 0) {
            files_list.data[item] = folder_files[item].map((doc) => {
              return { id: doc.id, name: doc.name };
            });
          }
        });
      } else {
        files_list.type = "month";
        if (e.category === "Annual_Documents_Statements") {
          // console.log("is Annual_Documents_Statements");

          Object.keys(folder_files).forEach((item) => {
            if (item !== "1099" && folder_files[item].length) {
              files_list.data[item] = folder_files[item].map((doc) => {
                return { id: doc.id, name: doc.name };
              });
            }
          });
        } else {
          files_list.data[e.category] = folder_files.map((doc) => {
            return { id: doc.id, name: doc.name };
          });
        }
      }
      this.showDownloadModal = true;
      this.downloadData = JSON.parse(JSON.stringify(files_list));
      // console.log(JSON.parse(JSON.stringify(files_list)));
    },
    onDownloadConfirm(e) {
      this.downloadStarted = true;
      this.batchDownloadRequest(e.data)
        .then((response) => {
          console.log(response);
          this.downloadData = null;
          this.downloadStarted = false;
          this.showDownloadModal = false;
          const blob = new Blob([response.data], { type: "application/zip" });
          saveAs(blob, "downloadedFiles.zip");
        })
        .catch(() => {
          this.downloadData = null;
          this.downloadStarted = false;
          this.showDownloadModal = false;
          alert(
            "An error ocurred during the download process. Please try again."
          );
        });
    },
    onCancelClicked() {
      this.downloadData = null;
      this.downloadStarted = false;
      this.showDownloadModal = false;
    },
    batchDownloadRequest(data) {
      return http({
        url: "/batchDownload",
        method: "POST",
        data: data,
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json" },
      });
    },
    handleAllAccountsDownload() {
      this.showAccountsDownload = true;
      this.showAccountsDownloadKey++;
      const localStorageAccounts = JSON.parse(
        localStorage.getItem("AccountsList")
      );

      const accounts_list = localStorageAccounts.map(function (item) {
        return {
          id: item.id,
          ab: item.e1_customer_number_c,
        };
      });
      this.accountsDownloadData = { accounts_list };
      // console.log(this.accountsDownloadData);
    },
    getDocuments() {
      if (localStorage.getItem("SelectedAccount") === null) {
        return true;
      }
      const config = {
        // headers:{
        //     'token': Admndata.access_token,
        //     // 'Content-Type': 'application/x-www-form-urlencoded'
        // },
        withCredentials: true,
      };
      // var querystring = require("querystring");
      // let data = querystring.stringify({
      //   contact_id: userdata.current_user.id //gave the values directly for testing

      // });

      http
        .get("/getDocumentsList/" + this.account, config)
        .then((response) => {
          // console.log(response.data);
          // this.dateloaded = true;
          let documents = response.data;

          documents.forEach((item) => {
            item.name = item.document_name;
            let doc_date = moment(item.document_date_c).format("MM/DD/YYYY");
            let doc_year = moment(item.document_date_c).format("YYYY");
            let doc_month = moment(item.document_date_c).format("MMMM");
            item.document_date_c = doc_date;
            item.doc_year = doc_year;
            item.doc_month = doc_month;
            console.log("check doc chear");

            this.documents_filtered['Annual Documents'][0]["1099"] = [];
            this.documents_filtered['Annual Documents'][0]["1099"] = [];
            this.documents_filtered['Annual Documents'][0]["1099"] = [];

            if (doc_year == this.current_year) {
              if (!item.name.includes("1099")) {
                this.documents_filtered[item.category_id][0][doc_month].push(
                  item
                );
              } else {
                this.documents_filtered[item.category_id][0]["1099"].push(item);
              }

              // this.documents_filtered[item.category_id][0][doc_month].push(item)
            }  else if(doc_year == (this.current_year - 1)){
              if (!item.name.includes("1099")) {
                this.documents_filtered[item.category_id][1][doc_month].push(
                  item
                );
              } else {
                this.documents_filtered[item.category_id][1]["1099"].push(item);
              }
            } else {
              if (!item.name.includes("1099")) {
                this.documents_filtered[item.category_id][2][doc_month].push(
                  item
                );
              } else {
                this.documents_filtered[item.category_id][2]["1099"].push(item);
              }
            }
          });
          // console.log(this.documents_filtered);
          // console.log(this.documents_filtered)
          moment().format("MMMM Do YYYY, h:mm:ss a");
        })
        .catch((error_data) => {
          console.log(error_data);
        });
    },
    getAccounts() {
      return new Promise((resolve, reject) => {
        let userdata = JSON.parse(localStorage.getItem("PortalMe"));
        var querystring = require("querystring");
        let data = querystring.stringify({
          contact_id: userdata.current_user.id, //gave the values directly for testing
        });
        const config = {
          // headers:{
          //     'token': Admndata.access_token,
          //     // 'Content-Type': 'application/x-www-form-urlencoded'
          // },
          withCredentials: true,
        };
        http
          .post(`/getAccounts`, data, config)
          .then((response) => {
            // console.log("accounts", response)
            this.loadstate = false;
            // console.log(response.data);
            let account_list = response.data;
            account_list = account_list.sort((a,b) =>  {
                  const item_a = a.e1_customer_number_c.toUpperCase();
                  const item_b = b.e1_customer_number_c.toUpperCase();
                  return item_a < item_b ? -1 : item_a > item_b ? 1 : 0
              });
            account_list.every((item)=>{console.log(item.e1_customer_number_c); return true;});
            // Save list of related accounts
            this.accounts_list = account_list;
            localStorage.setItem(
              "AccountsList",
              JSON.stringify(this.accounts_list)
            );
            // console.log(this.accounts_list)
            this.account_name = response.data[0].name;
            // localStorage.setItem("SelectedAccount", JSON.stringify({id: response.data[0].id, name: response.data[0].name}))
            // console.log(this.account_name)
            resolve(this.account_name);
          })
          .catch((error_data) => {
            reject(error_data);
            console.log(error_data.response.data);
          });
      });
    },
    getData() {
      // console.log("Method called from mounthed hook");
      if (localStorage.getItem("PortalAuth") === null) {
        // console.log("User data not present");
        // this.$router.push({ name: "login" });
        window.location.href = "/login";
      }

      //set current_year
      this.current_year = moment().format("YYYY");

      // console.log("current year", this.current_year);
      this.last_year = moment().subtract(1, "year").format("YYYY");
      // console.log("last year", this.last_year);
      // if(localStorage.getItem("userdata") === null){
      //   console.log("User data not present");
      //   this.$router.push({ name: "login" });
      // }
      let userdata = JSON.parse(localStorage.getItem("PortalMe"));
      // console.log(userdata)
      // let Admndata = JSON.parse(localStorage.getItem("AdminAuth"));
      this.username = userdata.current_user.full_name;

      this.account = userdata.current_user.account_ids[0];

      //
    },
  },

  mounted() {
    // Generate list of years
    const startYear = this.start_year;
    const currentYear = new Date().getFullYear();
    // const currentYear = 2023;

    const yearsDiff = currentYear - startYear;
    for (let i = 0; i <= yearsDiff; i++) {
      this.years_list.push({
        idx: i,
        year: this.start_year + i,
      });
      this.years_list = this.years_list.sort((a, b) => b.year - a.year);
    }

    // let router = this.$router;
    http.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log("http error found", error);
        if (401 === error.response.status || 403 === error.response.status) {
          console.log("Session expired");
          localStorage.clear();
          // this.clearSiteData()
          var cookies = document.cookie.split(";");

          for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
          // router.push('/login?session=expired');
          window.location.href = "/login?session=expired";
        } else {
          return Promise.reject(error);
        }
      }
    );

    this.getData();
    this.show = true;
    if (localStorage.getItem("SelectedAccount") === null) {
      this.account_selected = false;
      this.getAccounts().then((response) => {
        console.log(response);
        // const button = document.getElementById('accountBtn');
        // button.click();
      });
    } else {
      this.account_selected = true;
      let loadAccount = JSON.parse(localStorage.getItem("SelectedAccount"));
      this.account = loadAccount.id;
      this.accounts_list = JSON.parse(localStorage.getItem("AccountsList"));
    }
    this.getDocuments();
    this.show = true;
    this.dateloaded = true;

    let loadAccountData = JSON.parse(localStorage.getItem("AccountsList"));
    let my_account = JSON.parse(localStorage.getItem("SelectedAccount"));
    let account_ui = loadAccountData.find((item) => {
      return item.id == my_account.id;
    });
    try {
      let bunit = account_ui.business_unit_new_c.slice(
        1,
        account_ui.business_unit_new_c.length
      );
      bunit = bunit.slice(0, -1);
      bunit = bunit.split("^");

      bunit.forEach((item) => {
        console.log(item);
        if (
          item == "Farm" ||
          item == "Corporate" ||
          item == "Collateral Inspection" ||
          item == "Consultations" ||
          item == "Real Estate"
        ) {
          this.unit = "Farm";
          localStorage.setItem("isEnergy", false);
          // document.body.style.backgroundColor = "#12ad2b";
          throw "Break";
        } else if (item == "Energy") {
          this.unit = "Energy";
          // document.body.style.backgroundColor = "rgb(34, 175, 226)";
          this.isEnergy = true;
          localStorage.setItem("isEnergy", true);
          throw "Break";
        }
      });
    } catch (e) {
      // if (e !== 'Break') throw e
      console.log(e);
    }
  },
};
</script>
<style scoped>
.main-header-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.downloadbtn {
  background-color: #90c44b !important;
  color: #ffffff;
}
.bgimage {
  position: fixed;
  top: 1;
  /* background: red; */
  width: 100%;
  height: 100%;
}

.v-tabs--grow > .v-tabs-bar .v-tab {
  flex: 1 0 auto;
  max-width: none;
  width: 25%;
}

.home-content {
  width: 90%;
  margin: auto;
  padding: 50px;
}

.folder-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.isEnergy {
  /**
    R: 34 
    G: 175 
    B: 226 
  */
  color: rgb(34, 175, 226);
}

.isFarm {
  color: #0085ca;
}

.v-tab--active {
  background-color: #c2c2c2;
}

.folder-element {
  /* width: 55%; */
  padding-right: 10px;
  /* max-width: 200px; */
}

.folder-element >>> .btn:hover {
  background-color: unset;
}

.folder-element >>> .filecontainer[data-v-d0d5a840] .v-btn__content i {
  margin-left: 80px;
}

/* .containerdata:deep(.v-expansion-panel-content){
        padding: unset;
    } */
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.6s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.folder-element >>> .v-expansion-panel-header {
  /* width: 50%; */
}

.folder-element >>> .theme--light.v-btn.v-btn--has-bg {
  background-color: unset;
}

.folder-element >>> .theme--light.v-btn.v-btn--has-bg:hover {
  background-color: unset;
}

.folder-element >>> .v-btn--is-elevated {
  box-shadow: unset;
}

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 100000;
  top: 1px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  text-align: center;
  width: 250px;
  margin: auto;
  padding: 10px;
}

.content h4 {
  margin: 10px;
}
</style>
