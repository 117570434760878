<template>
  <div class="filecontainer">
    <ul>
      <li>
        <!-- <v-icon large color="green darken-2">
          mdi-file-document-outline
        </v-icon> -->
        <!-- <a

          >{{ doc.document_name }} - {{ doc.document_date_c }}</a
        > -->
        <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color=""  v-bind="attrs" v-on="on">
            <v-icon large color="green darken-2">
          mdi-file-document-outline
        </v-icon> 

        
        <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color=""
          v-bind="attrs"
          v-on="on"
        >
        <span v-if="is1099">
          {{ doc.document_name }}
        </span>
        <span v-else>
          {{ doc.document_name }} - {{ doc.document_date_c }}
        </span>
        </v-btn>
      </template>
        <span v-if="is1099">
          {{ doc.document_name }}
        </span>
        <span v-else>
          {{ doc.document_name }} - {{ doc.document_date_c }}
        </span>
    </v-tooltip>
          </v-btn>
        </template>
        <v-card id="iframevcard">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Close</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if='docloading'>
              <v-btn dark text @click="dialog = false">
                Loading Document...
              </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="downloadFile(doc.id)">
                <v-icon>mdi-download</v-icon>Download &nbsp;&nbsp;&nbsp;&nbsp; {{ doc.document_name }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list three-line subheader id="iframesubheader">
            <v-subheader v-if='docloading'>Loading Document...</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <!-- <iframe :src="`${back_end}/validator.php?action=getDocument&document_name=${doc.document_name}&documentId=${doc.id}`" frameborder="0" height=725px @load="docloading = false" >Content</iframe>-->
                <iframe :id="`doc-${doc.id}`" :src="`${back_end}/readFile?t&document_name=${doc.document_name}&documentId=${doc.id}#toolbar=0&navpanes=0&scrollbar=0`" frameborder="0" height=725px @load="onLoadHandler" ></iframe>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-row>
      </li>
    </ul>
    
  </div>
</template>
<script>
import httpfile from "../http-common";

export default {
  props: {
    doc: {
      type: String
    },
    is1099: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      back_end: process.env.VUE_APP_BACKEND,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      docloading: true
    };
  },
  methods: {
      onLoadHandler(){
          this.docloading = false
      },
      downloadFile(id){
        console.log(id);
        httpfile.defaults.withCredentials = true;
        const url = `${this.back_end}/readFile?t&document_name=${this.doc.document_name}&documentId=${this.doc.id}`
        httpfile({
          url: url,
          method: 'GET',
          withCredentials: true,
          responseType: 'blob',
        }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 
        'application/pdf'}));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.doc.document_name);
        document.body.appendChild(fileLink);
        fileLink.click();
        })
        .catch(error => {
          console.log("download error", error)
        });
      }
  }
};
</script>

<style scoped>

#iframesubheader{
  height: calc(100% - 64px);
  margin-top: 64px;
}

ul li{
    list-style: none;
}
.filecontainer {
  margin-left: 25px;
}
iframe{
display:block;
    width:100%;
    height: 100%;    
}
#iframevcard{
  padding-top: 64px;
}
header{
  height: 64px;
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
    height: 100%;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    height: 100%;
}
.filecontainer >>> .v-btn__content{
    justify-content: start;
}
.filecontainer >>> .v-btn{
  text-transform: unset;
  letter-spacing: unset;
}
</style>
