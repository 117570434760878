<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        width="auto"
      >
        <!-- <template v-slot:activator="{ props }">
          <v-btn
            color="primary"
            v-bind="props"
          >
            Open Dialog
          </v-btn>
        </template> -->
        <v-card>
          <v-card-title class="text-h5" v-if="hasData">
            Do you want to start the documents download?
          </v-card-title>
          <v-card-title class="text-h5" v-if="!hasData">
            The selected folder is empty.
          </v-card-title>
          <v-card-text v-if="!started && hasData">The download time can vary depending on the number of documents</v-card-text>
          <v-card-text v-if="started && hasData">The download process has started.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1" class="btn"
              variant="text"
              @click="cancelClicked"
              v-if="!started"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green" class="btn btn-success"
              variant="text" 
              @click="confirmClicked"
              v-if="hasData && !started"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  export default {
    props: ['dialog', 'data', 'started'],
    data () {
      return {
        hasData: false
      }
    },
    methods: {
        confirmClicked(){
            this.$emit('download-confirm', {data: this.data});
        },
        cancelClicked(){
            this.$emit('cancel-clicked', true);
        }
    },
    watch: { 
        data: function(after) { // watch it
            console.log(after)
            this.hasData = Object.keys(this.data.data).length ? true : false;
        }
      }
  }
</script>
<style scoped>
    .btn{
        margin-left: 1rem;
    }

</style>