<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-icon large color="" class="isEnergy"> mdi-folder </v-icon>
          <div class="paneltitle" v-if="type == 'year'" v-bind:class="{ isFarmGreen: !isenergy }">
            <h5> {{ item_title }}
              <v-menu open-on-hover top offset-y>
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    <v-icon color="" class="isEnergy" > mdi-dots-horizontal </v-icon>
                  </v-btn>
                </template> -->

                <!-- <v-list>
                  <v-list-item class="download-btn" @click="downloadClicked">
                    Download
                  </v-list-item>
                </v-list> -->
              </v-menu>
            </h5>
          </div>
          <div class="paneltitle" v-else v-bind:class="{ isFarmGreen: !isenergy }">
            <span> {{ item_title }} 
              <v-menu open-on-hover top offset-y>
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    <v-icon color="" class="isEnergy" > mdi-dots-horizontal </v-icon>
                  </v-btn>
                </template> -->

                <!-- <v-list>
                  <v-list-item class="download-btn" @click="downloadClicked">
                    Download
                  </v-list-item>
                </v-list> -->
              </v-menu>
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="wrapper">
            <slot></slot>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: 'MainFolder',
  props: ['item_title', 'type', 'isenergy', 'path', 'category', 'item_year', 'item_month'],
  methods: {
    downloadClicked() {
      const data = {
        type: this.type === "year" ? "year" : "month",
        path: this.path,
        month: this.type === "year" ? null : this.item_month,
        year: this.item_year,
        category: this.category
      }
      this.$emit('download-clicked', data)
    }
  },
}
</script>
<style scoped>
.v-expansion-panel {
  margin-bottom: 10px;
}

.v-icon.v-icon {
  justify-content: unset;
}

.v-expansion-panel::before {
  box-shadow: unset;
}

.isEnergy {
  /**
    R: 144 
    G: 195 
    B:76 

  */
  color: rgb(144, 195, 76) !important;
}

.isFarm {
  color: #fedd00 !important;
}

.isFarmGreen {
  /* color: #12ad3b; */
}

.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
  padding: 0 0 0 18px;
}

.v-expansion-panel-header {
  padding: unset
}</style>