<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        width="800px"
      >
        <!-- <template v-slot:activator="{ props }">
          <v-btn
            color="primary"
            v-bind="props"
          >
            Open Dialog
          </v-btn>
        </template> -->
        <v-card class="card">
          <!-- <v-card-title class="text-h5">
            Download Statements of {{ data.accounts_list.length }} customer #
          </v-card-title> -->
          <v-alert
            class="alertElement"
            v-if="showAlert"
            text=""
            variant="outlined"
          >
            <h6>{{ alertMessage }}</h6>
          </v-alert>
          <v-card-text>
            <v-select
                label="Category"
                v-model="category"
                :items="categories"
                item-text="label"
                item-value="item"
                :disabled="processing"
                
            ></v-select>
            <div class="date_control">
              <v-select
                  :label="monthsLabel"
                  v-model="month"
                  :items="months"
                  item-text="name"
                  item-value="index"
                  class="fixedsize"
              ></v-select>
              <v-select
                  label="Year"
                  v-model="year"
                  :items="years"
                  class="fixedsize"
              ></v-select>
            </div>
            <div class="accounts_controls">
              <v-select
                :items="accounts_list"
                label="Customer #"
                item-value="id"
                item-text="e1_customer_number_c"
                multiple
                :disabled="this.accounts_list.length === 1"
                density="comfortable"
                class="fixedsize"
                v-model="selectedaccounts"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 5">
                    <span>{{ item.e1_customer_number_c }}</span>
                  </v-chip>
                  <span
                    v-if="index === 5"
                    class="text-grey text-caption align-self-center"
                  >
                    (+{{ selectedaccounts.length - 5 }} others)
                  </span>
                </template>
              </v-select>
              <v-checkbox v-if="this.accounts_list.length > 1" label="Select all" @change="selectAllClicked" v-model="allAb"></v-checkbox>
            </div>
          </v-card-text>
          
          <v-card-text v-if="!started">The download time can vary depending on the number of documents</v-card-text>
          <v-card-text v-if="started && hasData">The download process has started.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1" class="btn"
              variant="text"
              @click="cancelClicked"
              v-if="!started"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green" class="btn btn-success"
              variant="text" 
              @click="confirmClicked"
              v-if="!started"
              :loading="processing"
              :disabled="category === '' || month === null || selectedaccounts.length === 0"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
    import http from "../http-common";
    import { saveAs } from 'file-saver';

  export default {
    props: ['dialog', 'data', 'started', 'years', 'accounts_list'],
    data () {
      return {
        hasData: false,
        month: null,
        year: null,
        processing: false,
        initialMonths: [
            {name: "January", index: "01"},
            {name: "February", index: "02"},
            {name: "March", index: "03"},
            {name: "April", index: "04"},
            {name: "May", index: "05"},
            {name: "June", index: "06"},
            {name: "July", index: "07"},
            {name: "August", index: "08"},
            {name: "September", index: "09"},
            {name: "October", index: "10"},
            {name: "November", index: "11"},
            {name: "December", index: "12"},
        ],
        months: [
            {name: "January", index: "01"},
            {name: "February", index: "02"},
            {name: "March", index: "03"},
            {name: "April", index: "04"},
            {name: "May", index: "05"},
            {name: "June", index: "06"},
            {name: "July", index: "07"},
            {name: "August", index: "08"},
            {name: "September", index: "09"},
            {name: "October", index: "10"},
            {name: "November", index: "11"},
            {name: "December", index: "12"},
        ],
        category: "",
        categories: [
          {item: 'Statements', label: 'Statements'},
          {item: 'Annual Documents', label: 'Annual Documents'},
          {item: 'Reports', label: 'Reports'},
          {item: 'Documents', label: 'Miscellaneous'}
        ],
        select: [],
        selectedaccounts: "",
        selectAll: false,
        allAb: false,
        startDownload: true,
        accountsWithOutDocuments: [],
        searchMonths: false,
        monthsLabel: "Months",
        showAlert: false,
        alertMessage: ""
    }
    },
    mounted() {
      if(this.accounts_list.length == 1){
            this.selectedaccounts = this.accounts_list[0].id;
          }
    },
    methods: {
        resetData(individualAccount = false){
          this.category = ""; 
          if(!individualAccount){
            this.selectedaccounts = []; 
          }
          this.allAb = false; 
          this.month = ""; 
          this.year = "";
        },
        selectAllClicked(){
          console.log("all clicked");
          let accounts = this.accounts_list;
          if(this.allAb){
              this.selectedaccounts = accounts.map(item => {
                return item.id
              });
            }
            if(!this.allAb ){
              this.selectedaccounts = [];
            }
        },
        showAlertHandler(message){
          this.showAlert = true;
          this.alertMessage = message;
        },
        closeAlert(){
          this.showAlert = false;
          this.alertMessage = '';
        },
        confirmClicked(){
            this.closeAlert();
            if((this.year === '' || this.month === '') && this.searchMonths === false){
                // alert("Month and Year fields are required.");
                this.showAlertHandler("Month and Year fields are required.")
                return false;
            }
            // if((this.year === '' || this.month === '') && this.searchMonths === false){
            //     // alert("Month and Year fields are required.");
            //     this.showAlertHandler("Month and Year fields are required.")
            //     return false;
            // }
            // if(!this.selectedaccounts.length){
            //     // alert("Select at least one account to load the list of months.");
            //     this.showAlertHandler("Select at least one account to load the list of months.");
            //     return false;
            // }
            this.processing = true;
            console.log('validating typeof')
            if (typeof this.selectedaccounts === "string") {
              this.selectedaccounts = [this.selectedaccounts];
            }
            let accountsListForRequest = [];
            if(!this.searchMonths){
              this.selectedaccounts.forEach(selectedItem => {
                const account_details = this.accounts_list.find((item) => item.id == selectedItem);
                accountsListForRequest.push({id: account_details.id, ab: account_details.e1_customer_number_c});
              })
            }

            const payload = {
                documents_date: `${this.year}-${this.month}-01`,
                accounts_list: accountsListForRequest,
                count_flag: false,
                category: this.category,
                startDownload: this.startDownload,
                searchMonths: this.searchMonths
            };

            if(this.searchMonths){
              console.log("Search...");
              let accountsListForRequestSearch = [];

              this.accounts_list.forEach((item) => {
                accountsListForRequestSearch.push({id: item.id, ab: item.e1_customer_number_c});
              });
              

              payload.accounts_list = accountsListForRequestSearch;
              payload.documents_year = this.year;
              this.startDownload  = false;
              this.monthsLabel = "Loading list...";
            }

            this.batchDownloadRequest(payload, this.startDownload).then(response => {
                if(!this.startDownload && response?.data?.months){
                  // if(response?.data?.noDocuments.length === 0){
                  //   this.startDownload = true;
                  //   this.confirmClicked();
                  //   return;
                  // }
                  if(response?.data?.months){
                    console.log(response.data);
                    this.searchMonths = false;
                    this.processing = false;
                    this.startDownload = true;
                    this.months = this.initialMonths;
                    this.months =  this.months.filter(availableMonth => {
                      if(response.data.months.includes(availableMonth.index)){
                        return availableMonth;
                      }
                    })
                    this.monthsLabel = `Select a month (${this.months.length} found)`;
                    this.month = '';
                    return;
                  }

                  this.processing = false;
                  this.accountsWithOutDocuments = response.data.noDocuments;
                  this.startDownload = true;
                  this.searchMonths = false;
                  return
                }


                // console.log(response.status == 404);
                if(response.status === 201){
                    // alert("No files found, please check the month and year selected.")
                    this.showAlertHandler("No files found, please check the month and year selected.");
                    this.processing = false;
                    //       this.$emit('accounts-cancel-clicked', true);

                    return true;
                }
                this.downloadData = null;
                this.downloadStarted = false;
                this.showDownloadModal = false;
                const blob = new Blob([response.data], { type: 'application/zip' });
                saveAs(blob, `${this.category}-${payload.documents_date}.zip`);
                this.processing = false;
                if(this.startDownload){
                  this.resetData(this.accounts_list.length === 1 ? true : false);
                  this.$emit('accounts-cancel-clicked', true);
                }
            }).catch((error) => {
                this.downloadData = null;
                this.downloadStarted = false;
                this.showDownloadModal = false;
                console.log("error downloading", error)
                this.showAlertHandler("An error ocurred during the download process. Please try again.");
                // alert("An error ocurred during the download process. Please try again.")
            })
            // .finally(() => {
            //     this.processing = false;
            //     if(this.startDownload){
            //       this.resetData();
            //       this.$emit('accounts-cancel-clicked', true);
            //     }
            // });
        },
        cancelClicked(){
            this.closeAlert();
            this.resetData();
            this.$emit('accounts-cancel-clicked', true);
        },
        batchDownloadRequest(data, arraybuffer){
            console.log("arraybuf", arraybuffer);
            const url = arraybuffer === true ? "/allAccountsDocumentsDownload" :  "/searchAnnualDocsMonths";
            console.log("URL:", url)
            return http({
                url: url,
                method: 'POST',
                data: data,
                responseType: arraybuffer ? 'arraybuffer' : 'json',
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            })
        }
    },
    watch: { 
        // allAb: function(after) { // watch it
        //     console.log(after)
        //     if(after){
        //       this.selectedaccounts = this.accounts_list.map(item => {
        //         return {id: item.id, ab: item.e1_customer_number_c}
        //       })
        //     }
        //     if(!after ){
        //       this.selectedaccounts = [];
        //     }
        // },
        selectedaccounts: function(after) { // watch it
          console.log(after)
          if(after.length !== this.accounts_list.length){
            this.allAb = false;
          }else{
            this.allAb = true;
          }
        },
        accounts_list: function(){
          console.log("Watching accounts list")
          if(this.accounts_list.length == 1){
            this.selectedaccounts = this.accounts_list[0].id;
          }
        },
        category: function(after) {
          if(after === 'Annual Documents'){
            this.searchMonths = true;
            this.confirmClicked();
          }else{
            this.monthsLabel = 'Select a month'
            this.months = this.initialMonths;
            this.startDownload = true;
            this.searchMonths = false;
          }
        },
        years: function(){
          this.year = this.years[0]
        },
        year: function(){
          if(this.category === 'Annual Documents'){
            this.month = '';
            this.searchMonths = true;
            
            this.confirmClicked();
          }
        },
        month: function(after) {
          if(after !== '' && this.category === 'Annual Documents'){
            this.searchMonths = false;
          }
        }
      }
  }
</script>
<style scoped>
    .card{
      padding: 2rem 1rem;
    }
    .btn{
        margin-left: 1rem;
    }
    .select {
      width: 100px;
      max-height: 60px;
      font-size: 11px;
    }
    
    .col {
      max-width: 100px;
      max-height: 60px;
    }
    .v-input.fixedsize{
      max-width: 346px;
    }
    .accounts_controls{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .accounts_controls div:nth-child(2), .date_control div:nth-child(2){
      padding-left: 1rem;
    }
    .date_control{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .alertElement{
      background-color: #FEF0E4 !important;
      color: #FB8D01 !important;
    }
    .v-alert--text:before {
      background-color: unset;
    }
</style>